<template>
  <div class="student-question-report">
    <h3 class="student-question-report__section-title">
      {{ $t('personalAssessment.assessmentReport.tableTitle') }}
    </h3>
    <p class="student-question-report__section-subtitle">
      {{
        $tc(
          'personalAssessment.assessmentReport.tableSubtitle',
          questions.length,
          [questions.length],
        )
      }}
    </p>
    <div
      v-if="$mq.includes('small')"
      class="student-question-report__cards-list"
    >
      <QuestionCard
        v-for="(item, index) in questions"
        :key="index"
        :index="index + 1"
        :loading="loading"
        :question="item"
        @click.native="selectQuestion(item.id)"
      />
    </div>
    <QuestionsTable
      v-else
      :content="questions"
      :loading="loading"
      @select-question="selectQuestion($event)"
    />
  </div>
</template>

<script>
import QuestionCard from './QuestionCard'
import QuestionsTable from './QuestionsTable'

export default {
  name: 'StudentQuestionsReport',
  components: {
    QuestionCard,
    QuestionsTable,
  },
  props: {
    loading: Boolean,
    questions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectQuestion(id) {
      this.$emit('select-question', id)
    },
  },
}
</script>

<style lang="sass" scoped>
.student-question-report

  &__section-title
    margin-top: $size-m

  &__section-subtitle
    margin-top: $size-xxs
    color: $color-ink-light

  &__cards-list
    margin: $size-m 0
</style>
