<template>
  <skeleton-loader
    border-radius="50%"
    class="radial-progress-loader"
    :height="size"
    :width="size"
  />
</template>

<script>
export default {
  name: 'RadialProgressSkeleton',
  props: {
    size: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.radial-progress-loader::after
  +pseudo
  position: absolute
  top: 10px
  left: 10px
  right: 10px
  bottom: 10px
  background: $color-white
  border-radius: 50%
</style>
