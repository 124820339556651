<template>
  <div class="question-drawer-hit-rate">
    <div class="question-drawer-hit-rate__donut">
      <RadialProgressBar
        :completed-steps="roundedHitRate"
        :diameter="donutSize"
        inner-stroke-color="#EFF0F0"
        start-color="#438DE4"
        stop-color="#438DE4"
        :stroke-width="10"
        :total-steps="100"
      >
        <h4><b>{{ roundedHitRate }}%</b></h4>
      </RadialProgressBar>
    </div>
    <div class="question-drawer-hit-rate__text">
      <div class="question-drawer-hit-rate__text__title">
        {{
          $t('personalAssessment.assessmentReport.questionReport.hitRateLong')
        }}
      </div>
      <div class="question-drawer-hit-rate__text__count">
        <h5>
          {{ $tc(
            'personalAssessment.assessmentReport.questionReport.studentHits',
            count, [hitCount, count],
          ) }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from '@/mixins/mediaQueries'

const donutSize = {
  SMALL: 110,
  DEFAULT: 130,
}

export default {
  name: 'QuestionDrawerHitRate',
  components: {
    RadialProgressBar,
  },
  mixins: [mediaQueries],
  props: {
    hitRate: {
      type: Number,
      required: true,
    },
    hitCount: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    roundedHitRate() {
      return Math.round(this.hitRate)
    },
    donutSize() {
      return this.$mq.includes('small') ? donutSize.SMALL : donutSize.DEFAULT
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center

  +mq-m--mf
    flex-flow: row
    align-items: center
    justify-content: start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      color: $color-ink-light
      font-size: 11px
      text-transform: uppercase
      letter-spacing: 1.3px
      margin-bottom: $size-xs
</style>
