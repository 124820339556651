<template>
  <box
    class="question-card"
    padding="0"
  >
    <div class="question-card__statement-section">
      <div class="question-card__statement-section__index-badge">
        <skeleton-loader
          v-if="loading"
          border-radius="50%"
          :height="$tokens.size_l"
          :width="$tokens.size_l"
        />
        <SBadge v-else>
          {{ String(index).padStart(2, 0) }}
        </SBadge>
      </div>
      <skeleton-loader
        v-if="loading"
        class="question-card__statement-section__statement-loader"
        :height="$tokens.size_s"
        width="100%"
      />
      <h6
        v-else
        class="question-card__statement-section__statement"
      >
        {{ getTruncatedText(question.item.wording) }}
      </h6>
    </div>
    <div
      :class="[
        'question-card__answer-section',
        { '--loading': loading },
      ]"
    >
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        :width="$tokens.size_l"
      />
      <div
        v-else
        class="question-card__answer-section__answer-badge"
      >
        <SBadge
          v-if="question.markedOption >= 0"
          class="question-card__answer-section__answer-badge__answered"
          :icon-right="answerBadge.icon"
          :variation="answerBadge.variation"
        >
          {{ answerLetters[question.markedOption] }}
        </SBadge>
        <SBadge
          v-else
          icon="minus"
          variation="neutral"
        />
      </div>
    </div>
  </box>
</template>

<script>
export default {
  name: 'QuestionCard',
  props: {
    loading: Boolean,
    index: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    answerLetters() {
      return ['A', 'B', 'C', 'D', 'E']
    },
    stringTruncateLentgh() {
      const defaultLength = 15

      return {
        x_small: 14,
        small: 20,
      }[this.$mq] || defaultLength
    },
    answerBadge() {
      const correctOption = Number(this.question.correctOption)

      return correctOption === this.question.markedOption
        ? {
          icon: 'check',
          variation: 'success',
        }
        : {
          icon: 'x',
          variation: 'danger',
        }
    },
  },
  methods: {
    getTruncatedText(string) {
      return `${string.substr(0, this.stringTruncateLentgh)}...`
    },
  },
}
</script>

<style lang="sass" scoped>
$border: 1px solid $color-ink-lightest

.question-card
  margin-bottom: $size-s
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer

  &__statement-section
    width: 75%
    display: flex
    align-items: center

    &__index-badge
      padding: $size-s
      border-right: $border

    &__statement-loader
      margin: 0 $size-s

    &__statement
      margin-left: $size-s
      margin-right: auto

  &__answer-section
    width: 25%
    display: flex
    align-items: center
    justify-content: center
    border-left: $border

    &.--loading
      padding: $size-s

    &__answer-badge
      padding: $size-s

      &__answered
        background: transparent

        ::v-deep .sas-badge__text
          font-size: $font-size-m
</style>
