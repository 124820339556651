var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('STable',{staticClass:"questions-table",attrs:{"content":_vm.content,"fields":_vm.tableFields,"loading":_vm.loading,"skeleton-lines":3},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var row = ref.row;
return [(_vm.loading)?_c('skeleton-loader',{attrs:{"height":_vm.$tokens.size_l,"width":"100%"}}):_c('p',{staticClass:"questions-table__index"},[_vm._v(" "+_vm._s(_vm.getQuestionIndex(row.id))+" ")])]}},{key:"question",fn:function(ref){
var row = ref.row;
return [(_vm.loading)?_c('skeleton-loader',{attrs:{"height":_vm.$tokens.size_l,"width":"100%"}}):_c('p',{staticClass:"questions-table__question"},[_vm._v(" "+_vm._s(_vm.getTruncatedText(row.item.wording))+" ")])]}},{key:"answer",fn:function(ref){
var row = ref.row;
return [(_vm.loading)?_c('skeleton-loader',{attrs:{"height":_vm.$tokens.size_l,"width":"100%"}}):_c('div',[(row.markedOption >= 0)?_c('SBadge',{staticClass:"questions-table__answer-badge",attrs:{"icon-right":_vm.getAnswerBadge(row).icon,"variation":_vm.getAnswerBadge(row).variation}},[_vm._v(" "+_vm._s(_vm.answerLetters[row.markedOption])+" ")]):_c('SBadge',{attrs:{"icon":"minus","variation":"neutral"}})],1)]}},{key:"feedback",fn:function(ref){
var row = ref.row;
return [(_vm.loading)?_c('skeleton-loader',{attrs:{"height":_vm.$tokens.size_l,"width":"100%"}}):_c('p',{staticClass:"questions-table__feedback"},[_vm._v(" "+_vm._s(_vm.answerLetters[row.correctOption])+" ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(_vm.loading)?_c('skeleton-loader',{attrs:{"height":_vm.$tokens.size_l,"width":"100%"}}):_c('s-button',{attrs:{"color":_vm.$tokens.color_primary,"icon":"arrow-right","variation":"tertiary"},on:{"click":function($event){return _vm.$emit('select-question', row.id)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }