<template>
  <drawer
    :background="$tokens.color_white"
    class="question-drawer"
    :overlay-enabled="false"
    :size="$tokens.device_m"
  >
    <DrawerNavigator
      :has-next="hasNext"
      :has-previous="hasPrevious"
      @close="$emit('on-close')"
      @next="$emit('change-selected-question', 'next')"
      @previous="$emit('change-selected-question', 'previous')"
    >
      <template #header>
        <skeleton-loader
          v-if="loading"
          height="33px"
          width="120px"
        />
        <div
          v-else
          class="question-drawer__header"
        >
          <h4 class="question-drawer__header__title">
            {{ $t(
              `${questionReportPath}.questionNumber`,
              { number: questionIndex },
            ) }}
          </h4>
          <template v-if="question.correctOption !== undefined">
            <SBadge
              :icon-left="badgeIcon(isAnsweredCorrectly)"
              :variation="badgeVariation(isAnsweredCorrectly)"
            >
              {{ $t(
                `${questionReportPath}.${resultLabel}`,
              ) }}
            </SBadge>
          </template>
        </div>
      </template>
      <template #child>
        <QuestionDrawerDetails
          :loading="loading"
          :question="enhancedQuestionDetails"
        />
        <template v-if="questionStats.hitRate !== undefined || loading">
          <QuestionDrawerHitRateSkeleton v-if="loading" />
          <QuestionDrawerHitRate
            v-else
            :count="questionStats.count"
            :hit-count="questionStats.hitCount || 0"
            :hit-rate="questionStats.hitRate || 0"
          />
        </template>
        <QuestionDrawerBodySkeleton v-if="loading" />
        <QuestionDrawerBody
          v-else
          :question="enhancedQuestionDetails"
        />
      </template>
    </DrawerNavigator>
  </drawer>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import badgeStyle from '@/mixins/badgeStyle'
import keyEvents from '@/mixins/keyEvents'
import QuestionDrawerBody from './QuestionDrawerBody'
import QuestionDrawerBodySkeleton from './QuestionDrawerBodySkeleton'
import QuestionDrawerDetails from './QuestionDrawerDetails'
import QuestionDrawerHitRate from './QuestionDrawerHitRate'
import QuestionDrawerHitRateSkeleton from './QuestionDrawerHitRateSkeleton'
import DrawerNavigator from './DrawerNavigator'

import services from '../../services'

export default {
  name: 'QuestionReportDrawer',
  components: {
    QuestionDrawerDetails,
    QuestionDrawerBody,
    QuestionDrawerBodySkeleton,
    QuestionDrawerHitRate,
    QuestionDrawerHitRateSkeleton,
    DrawerNavigator,
  },
  mixins: [mediaQueries, badgeStyle, keyEvents],
  props: {
    examId: {
      type: Number,
      default: null,
    },
    question: {
      type: Object,
      default: null,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      questionDetails: {},
      questionStats: {},
    }
  },
  computed: {
    questionReportPath() {
      return 'personalAssessment.assessmentReport.questionReport'
    },
    isAnsweredCorrectly() {
      if (this.question.markedOption < 0) {
        return null
      }

      return Number(this.question.correctOption) === this.question.markedOption
    },
    resultLabel() {
      if (this.question.markedOption < 0) {
        return 'notAnswered'
      }

      return this.isAnsweredCorrectly
        ? 'hit'
        : 'missed'
    },
    resultModifier() {
      return this.isAnsweredCorrectly
        ? 'success'
        : 'danger'
    },
    enhancedQuestionDetails() {
      return {
        correctOption: Number(this.question.correctOption),
        markedOption: this.question.markedOption,
        options: this.formattedOptions,
        ...this.questionDetails,
      }
    },
    formattedOptions() {
      const { formattedOptions } = this.questionDetails
      const parsed = formattedOptions
        ? JSON.parse(this.questionDetails.formattedOptions)
        : []

      return parsed.map((item, index) => ({
        id: index,
        description: item,
      }))
    },
  },
  watch: {
    question() {
      this.fetchQuestionDetails()
    },
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    this.fetchQuestionDetails()
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
  },
  methods: {
    pressEsc(event) {
      if (this.isEscKeyPressed(event)) {
        this.$emit('on-close')
      }
    },
    async fetchQuestionDetails() {
      try {
        this.loading = true
        const { data } = await services.fetchQuestionDetails(
          this.examId, this.question.itemId,
        )
        this.questionDetails = data
        this.fetchQuestionStats()
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('personalAssessment.feedback.fetchQuestionDetailsError'),
        })
      } finally {
        this.loading = false
      }
    },
    async fetchQuestionStats() {
      const { data } = await services.fetchQuestionStats(
        this.examId, this.question.questionId,
      )
      this.questionStats = data
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer
  &__header
    display: flex
    flex-flow: column
    align-items: flex-start

    +mq-m--mf
      flex-flow: row
      align-items: center

    .sas-badge

      +mq-m--mf
        margin-left: $size-s

    &__hit
      margin-left: $size-xs
      font-size: $font-size-s

      &.--success
        color: $color-success-dark

      &.--danger
        color: $color-danger-dark
</style>
