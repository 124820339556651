import { render, staticRenderFns } from "./QuestionDrawerHitRateSkeleton.vue?vue&type=template&id=3856744b&scoped=true&"
import script from "./QuestionDrawerHitRateSkeleton.vue?vue&type=script&lang=js&"
export * from "./QuestionDrawerHitRateSkeleton.vue?vue&type=script&lang=js&"
import style0 from "./QuestionDrawerHitRateSkeleton.vue?vue&type=style&index=0&id=3856744b&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3856744b",
  null
  
)

export default component.exports