export default {
  methods: {
    badgeIcon(correct) {
      if (correct === null) {
        return null
      }

      return correct
        ? 'check'
        : 'x'
    },
    badgeVariation(correct) {
      if (correct === null) {
        return 'neutral'
      }

      return correct
        ? 'success'
        : 'danger'
    },
  },
}
