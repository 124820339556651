<template>
  <div class="question-drawer-body">
    <header class="question-drawer-body__header">
      <div class="question-drawer-body__header__title">
        <h4>
          {{
            $t('personalAssessment.assessmentReport.questionReport.question')
          }}
        </h4>
      </div>
    </header>
    <section
      class="question-drawer-body__statement"
      v-html="question.formattedWording"
    />
    <ul class="question-drawer-body__options">
      <li
        v-for="(option, index) in question.options"
        :key="index"
        class="question-drawer-body__options__item"
      >
        <div class="question-drawer-body__options__item__key">
          <SBadge
            size="medium"
            :variation="optionVariation(option)"
          >
            {{ optionLetter[Number.parseInt(option.id, 10)] }}
          </SBadge>
        </div>
        <div
          class="question-drawer-body__options__item__text"
          v-html="option.description"
        />
      </li>
    </ul>
    <section class="question-drawer-body__explanation">
      <div class="question-drawer-body__explanation__title">
        <h4>
          {{
            $t('personalAssessment.assessmentReport.questionReport.explanation')
          }}
        </h4>
      </div>
      <div
        v-if="question.embedHtml"
        class="question-drawer-body__explanation__video"
        v-html="question.embedHtml"
      />
      <div v-html="question.explanation" />
    </section>
  </div>
</template>

<script>
export default {
  name: 'QuestionDrawerBody',
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionLetter: ['A', 'B', 'C', 'D', 'E'],
    }
  },
  methods: {
    optionVariation(option) {
      if (option.id === this.question.correctOption) {
        return 'success'
      }
      if (option.id === this.question.markedOption) {
        return 'danger'
      }

      return 'neutral'
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-body
  overflow-x: auto
  padding-bottom: 3 * $size-xxl

  &__header
    padding: $size-l $size-l 0
    margin-bottom: $size-m
    +flex-space-between

    &__skill
      ::v-deep .sas-badge.--neutral
        background: $color-white
        border: 1px solid $color-ink-lighter

  &__statement,
  &__explanation
    ::v-deep img
      +embedded-image

    ::v-deep p
      +embedded-paragraph

  &__statement
    padding: 0 $size-l
    margin-bottom: $size-m

  &__options
    padding: 0 $size-l
    margin-bottom: $size-xl

    &__item
      margin-bottom: $size-s
      +flex-center-start

      &__key
        margin-right: $size-s

        ::v-deep .sas-badge
          border: 1px solid transparent

          &.--neutral
            background: $color-white
            border-color: $color-ink-lighter

  &__explanation
    border-top: 1px solid $color-ink-lightest
    padding: $size-l
    width: fit-content
    min-width: 100%

    &__title
      margin-bottom: $size-s

    &__video
      +proportional-video

      ::v-deep iframe
        +proportional-video-iframe
</style>
