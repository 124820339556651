<template>
  <Box
    :border-radius="$tokens.border_radius_m"
    class="hits-rate"
    padding="0"
  >
    <div class="hits-rate__body">
      <div class="hits-rate__chart">
        <skeleton-loader
          v-if="loading"
          border-radius="50%"
          :height="$mq.includes('large') ? '200px' : '182px'"
          :width="$mq.includes('large') ? '200px' : '182px'"
        />
        <RadialProgressBar
          v-else
          :completed-steps="hitRate || 0"
          :diameter="donutSize"
          inner-stroke-color="#EFF0F0"
          start-color="#438DE4"
          stop-color="#438DE4"
          :stroke-width="donutStroke"
          :total-steps="100"
        >
          <h2><b>{{ hitsRateValue }}</b></h2>
        </RadialProgressBar>
      </div>
      <div class="hits-rate__text">
        <h2 class="hits-rate__text__title">
          <skeleton-loader
            v-if="loading"
            :height="$tokens.size_xl"
            width="150px"
          />
          <template v-else>
            {{ title }}
          </template>
        </h2>
        <p class="hits-rate__text__count">
          <skeleton-loader
            v-if="loading"
            :height="$tokens.size_l"
            width="200px"
          />
          <template v-else>
            {{ subtitle }}
          </template>
        </p>
      </div>
    </div>
  </Box>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from '@/mixins/mediaQueries'

const donutSize = {
  SMALL: 182,
  MEDIUM: 200,
  LARGE: 216,
  DEFAULT: 140,
}

const donutStroke = {
  SMALL: 15,
  MEDIUM: 17,
  LARGE: 18,
  DEFAULT: 16,
}

export default {
  name: 'HitsRate',
  components: {
    RadialProgressBar,
  },
  mixins: [mediaQueries],
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    hitRate: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    donutSize() {
      if (this.$mq.includes('small')) {
        return donutSize.SMALL
      }
      if (this.$mq.includes('medium')) {
        return donutSize.MEDIUM
      }
      if (this.$mq.includes('large')) {
        return donutSize.LARGE
      }

      return donutSize.DEFAULT
    },
    donutStroke() {
      if (this.$mq.includes('small')) {
        return donutStroke.SMALL
      }
      if (this.$mq.includes('medium')) {
        return donutStroke.MEDIUM
      }
      if (this.$mq.includes('large')) {
        return donutStroke.LARGE
      }

      return donutStroke.DEFAULT
    },
    hitsRateValue() {
      return Number.isFinite(this.hitRate)
        ? `${parseInt(this.hitRate, 10)}%`
        : '-'
    },
  },
}
</script>

<style lang="sass" scoped>
.hits-rate
  margin-bottom: $size-m

  +mq-m--mf
    margin-bottom: 0

  &__body
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    padding: $size-xl
    height: 100%

  &__chart
    margin-bottom: $size-m

  &__text
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    max-width: 100%

    &__count
      font-size: $font-size-heading-6
      color: $color-ink-light
</style>
