<template>
  <div class="drawer-navigator">
    <header class="drawer-navigator__header">
      <slot name="header" />
      <div class="drawer-navigator__header__controls">
        <div class="drawer-navigator__header__controls__previous">
          <s-button
            :disabled="!hasPrevious"
            icon="arrow-left"
            variation="tertiary"
            @click="$emit('previous')"
          />
        </div>
        <div class="drawer-navigator__header__controls__next">
          <s-button
            :disabled="!hasNext"
            icon="arrow-right"
            variation="tertiary"
            @click="$emit('next')"
          />
        </div>
        <div class="drawer-navigator__header__controls__close">
          <s-button
            icon="x"
            variation="tertiary"
            @click="$emit('close')"
          />
        </div>
      </div>
    </header>
    <slot name="child" />
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'DrawerNavigator',
  mixins: [mediaQueries],
  props: {
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.drawer-navigator
  &__header
    padding: $size-s
      left: $size-l
    +flex-space-between

    &__controls
      +flex-center

      &__previous
        padding:
          right: $size-xs

      &__next
        border-right: 1px solid transparentize($color-ink-lightest, 0.5)
        padding:
          left: $size-xs
          right: $size-xs

      &__close
        padding:
          left: $size-xs
</style>
