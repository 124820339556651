<template>
  <div class="question-drawer-hit-rate">
    <div class="question-drawer-hit-rate__donut">
      <RadialProgressSkeleton
        :size="`${donutSize}px`"
      />
    </div>
    <div class="question-drawer-hit-rate__text">
      <div class="question-drawer-hit-rate__text__title">
        <skeleton-loader
          height="15px"
          width="200px"
        />
      </div>
      <div class="question-drawer-hit-rate__text__count">
        <skeleton-loader
          height="27px"
          width="190px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import RadialProgressSkeleton from './RadialProgressSkeleton'

const donutSize = {
  SMALL: 88,
  DEFAULT: 110,
}

export default {
  name: 'QuestionDrawerHitRate',
  components: {
    RadialProgressSkeleton,
  },
  mixins: [mediaQueries],
  computed: {
    donutSize() {
      return this.mq_xs ? donutSize.SMALL : donutSize.DEFAULT
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l

  +mq-s--mf
    +flex-center-start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      margin-bottom: $size-xs
</style>
