<template>
  <STable
    class="questions-table"
    :content="content"
    :fields="tableFields"
    :loading="loading"
    :skeleton-lines="3"
  >
    <template #index="{ row }">
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        width="100%"
      />
      <p
        v-else
        class="questions-table__index"
      >
        {{ getQuestionIndex(row.id) }}
      </p>
    </template>
    <template #question="{ row }">
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        width="100%"
      />
      <p
        v-else
        class="questions-table__question"
      >
        {{ getTruncatedText(row.item.wording) }}
      </p>
    </template>
    <template #answer="{ row }">
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        width="100%"
      />
      <div v-else>
        <SBadge
          v-if="row.markedOption >= 0"
          class="questions-table__answer-badge"
          :icon-right="getAnswerBadge(row).icon"
          :variation="getAnswerBadge(row).variation"
        >
          {{ answerLetters[row.markedOption] }}
        </SBadge>
        <SBadge
          v-else
          icon="minus"
          variation="neutral"
        />
      </div>
    </template>
    <template #feedback="{ row }">
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        width="100%"
      />
      <p
        v-else
        class="questions-table__feedback"
      >
        {{ answerLetters[row.correctOption] }}
      </p>
    </template>
    <template #action="{ row }">
      <skeleton-loader
        v-if="loading"
        :height="$tokens.size_l"
        width="100%"
      />
      <s-button
        v-else
        :color="$tokens.color_primary"
        icon="arrow-right"
        variation="tertiary"
        @click="$emit('select-question', row.id)"
      />
    </template>
  </STable>
</template>

<script>
import STable from './STable'

export default {
  name: 'QuestionsTable',
  components: {
    STable,
  },
  props: {
    loading: Boolean,
    content: {
      type: Array,
      required: true,
    },
  },
  computed: {
    compactFieldList() {
      return [
        {
          value: 'index',
          text: this.$t('personalAssessment.assessmentReport.tableFields.index'),
        },
        {
          value: 'question',
          text: this.$t('personalAssessment.assessmentReport.tableFields.question'),
        },
        {
          value: 'answer',
          text: this.$t('personalAssessment.assessmentReport.tableFields.answer'),
        },
      ]
    },
    extendedFieldList() {
      return [
        {
          value: 'index',
          text: this.$t('personalAssessment.assessmentReport.tableFields.index'),
        },
        {
          value: 'question',
          text: this.$t('personalAssessment.assessmentReport.tableFields.question'),
        },
        {
          value: 'answer',
          text: this.$t('personalAssessment.assessmentReport.tableFields.answer'),
        },
        {
          value: 'feedback',
          text: this.$t('personalAssessment.assessmentReport.tableFields.feedback'),
        },
        {
          value: 'action',
        },
      ]
    },
    tableFields() {
      return this.$mq.includes('small')
        ? this.compactFieldList
        : this.extendedFieldList
    },
    answerLetters() {
      return ['A', 'B', 'C', 'D', 'E']
    },
  },
  methods: {
    getQuestionIndex(id) {
      const index = this.content.findIndex((item) => item.id === id)
      const numberLength = 2

      return String(index + 1).padStart(numberLength, 0)
    },
    getTruncatedText(string) {
      const textSize = {
        MEDIUM: 30,
        DEFAULT: 55,
      }

      const truncateLength = this.$mq.includes('medium')
        ? textSize.MEDIUM
        : textSize.DEFAULT

      return `${string.substr(0, truncateLength)}...`
    },
    getAnswerBadge(row) {
      return Number(row.correctOption) === row.markedOption ? {
        icon: 'check',
        variation: 'success',
      } : {
        icon: 'x',
        variation: 'danger',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$font-weight-medium: 500
$font-weight-semi-bold: 600

.questions-table
  margin: $size-m 0

  &__index
    font-weight: $font-weight-semi-bold

  &__question
    font-weight: $font-weight-medium

  &__answer-badge
    background: transparent

    ::v-deep .sas-badge__text
      font-size: $font-size-m

  &__feedback
    font-weight: $font-weight-medium
</style>
