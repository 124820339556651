<template>
  <box>
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div class="placement-histogram">
        <h3>{{ $t('personalAssessment.assessmentReport.placement.title') }}</h3>
        <skeleton-loader
          v-if="loading"
          class="placement-histogram__explanation__loader"
          :height="$tokens.size_l"
          width="100%"
        />
        <p
          v-else
          key="explanation"
          class="placement-histogram__explanation"
          v-html="$t(
            'personalAssessment.assessmentReport.placement.explanation',
            [
              parseInt(studentTrack.percentage, 10),
              parseInt(studentTrack.lowerBound, 10),
              parseInt(studentTrack.upperBound, 10),
            ],
          )"
        />
        <div
          v-if="loading"
          class="placement-histogram__chart__loading"
        >
          <spinner />
        </div>
        <VerticalBarChart
          v-else
          :bar-width="chartConfig.barWidth"
          :bars="bars"
          class="placement-histogram__chart"
          :gutter="chartConfig.gutter"
          :height="chartConfig.height"
          :loading="loading"
          :total="100"
          :x-label="chartConfig.xLabel"
          :y-label="chartConfig.yLabel"
        />
        <div class="placement-histogram__legend">
          <ChartLegend>
            {{
              $t('personalAssessment.assessmentReport.placement.legend.student')
            }}
          </ChartLegend>
          <ChartLegend :color="$tokens.color_ink_lighter">
            {{
              $t('personalAssessment.assessmentReport.placement.legend.others')
            }}
          </ChartLegend>
        </div>
      </div>
    </transition>
  </box>
</template>

<script>
import { mapGetters } from 'vuex'

import VerticalBarChart from '@/components/VerticalBarChart'
import ChartLegend from '@/components/ChartLegend'

import services from '../services'

export default {
  name: 'PlacementHistogram',
  components: {
    VerticalBarChart,
    ChartLegend,
  },
  props: {
    examId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      studentHitPercentage: 0,
      histogram: [],
      loading: false,
      hasError: false,
    }
  },
  computed: {
    ...mapGetters(['assessment']),
    bars() {
      return this.histogram.map((item) => ({
        value: item.percentage,
        label: this.$t(
          'personalAssessment.assessmentReport.placement.range',
          [parseInt(item.lowerBound, 10), parseInt(item.upperBound, 10)],
        ),
        color: this.getBarColor(item),
      }))
    },
    histogramTracks() {
      return this.histogram.map((bar) => {
        const { lowerBound, upperBound } = bar
        const lowerBoundLabel = lowerBound.toLocaleString('pt-br')
        const upperBoundLabel = upperBound.toLocaleString('pt-br')

        return {
          value: bar.percentage,
          label: this.$t(
            'personalAssessment.assessmentReport.placement.range',
            [lowerBoundLabel, upperBoundLabel],
          ),
          color: bar.selected ? this.$tokens.color_primary : '',
        }
      })
    },
    selectedHistogramTrack() {
      return this.histogramTracks.find((bar) => bar.color)
    },
    chartConfig() {
      return {
        height: '144px',
        xLabel: '',
        yLabel: '',
        barWidth: '0.25fr',
        gutter: this.$mq.includes('small')
          ? this.$tokens.size_s
          : this.$tokens.size_m,
      }
    },
    studentTrack() {
      const { studentHitPercentage, histogram } = this

      return histogram.find((item) => studentHitPercentage >= item.lowerBound
        && studentHitPercentage <= item.upperBound)
    },
  },
  created() {
    this.loadPlacement()
  },
  methods: {
    reload() {
      this.loadPlacement(true)
    },
    async loadPlacement() {
      this.hasError = false
      this.loading = true
      try {
        const { data } = await services.fetchPlacementHistogram(this.examId)
        this.studentHitPercentage = data.studentHitPercentage
        this.histogram = data.histogram
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
    formatPercentage(percentage) {
      // eslint-disable-next-line no-magic-numbers
      return (Math.round(percentage * 10) / 10).toLocaleString('pt-br')
    },
    getBarColor(bar) {
      const { studentHitPercentage } = this
      const { lowerBound, upperBound } = bar
      const isBetweenBounds = studentHitPercentage >= lowerBound
        && studentHitPercentage <= upperBound

      return isBetweenBounds
        ? this.$tokens.color_primary_light
        : this.$tokens.color_ink_lighter
    },
  },
}
</script>

<style lang="sass" scoped>
.placement-histogram
  display: flex
  flex-flow: column
  justify-content: space-between
  height: 100%

  &__explanation
    font-size: $font-size-m
    font-weight: $font-weight-semi-bold
    text-align: center
    padding: $size-xs $size-s
    margin-top: $size-s
    color: $color-ink-light

    ::v-deep span
      font-size: $font-size-m
      font-weight: $font-weight-semi-bold
      color: $color-primary

    &__loader
      margin: $size-s 0

  &__chart

    ::v-deep .vertical-bar-chart__bars
      width: 100%

    &__loading
      display: flex
      align-items: center
      justify-content: center
      margin: $size-m 0

  &__legend
    display: flex
    flex-flow: column
    align-items: flex-start
    margin-top: $size-l

    +mq-s--mf
      flex-flow: row
      justify-content: center

    .sas-chart-legend ~ .sas-chart-legend
      margin-top: $size-xs

      +mq-s--mf
        margin-top: 0
        margin-left: $size-m

    &__loader
      margin-bottom: $size-s

      +mq-m--mf
        margin-bottom: 0
        margin-right: $size-l
</style>
