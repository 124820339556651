import { render, staticRenderFns } from "./PlacementHistogram.vue?vue&type=template&id=4335046e&scoped=true&"
import script from "./PlacementHistogram.vue?vue&type=script&lang=js&"
export * from "./PlacementHistogram.vue?vue&type=script&lang=js&"
import style0 from "./PlacementHistogram.vue?vue&type=style&index=0&id=4335046e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4335046e",
  null
  
)

export default component.exports