<template>
  <div class="assessment-report">
    <SHeader
      :breadcrumbs="[
        $t('personalAssessment.assessmentReport.assessments'),
        $t('personalAssessment.assessmentReport.finalReport'),
      ]"
      icon-hidden
      :loading="loading"
      :title="assessment.name"
      toolbar
      @back="goBack()"
    />
    <wrapper>
      <div class="assessment-report__charts-section">
        <HitsRate
          class="assessment-report__charts-section__hits-rate"
          :hit-rate="hitRate"
          :loading="loading"
          :subtitle="
            $tc(
              'personalAssessment.assessmentReport.questionsCount',
              questions.length,
              [correctAnswersCount, questions.length],
            )
          "
          :title="$t('personalAssessment.assessmentReport.hits')"
        />
        <PlacementHistogram
          class="assessment-report__charts-section__placement-histogram"
          :exam-id="Number(examId)"
        />
      </div>
      <StudentQuestionsReport
        :loading="loading"
        :questions="questions"
        @select-question="selectQuestion($event)"
      />
      <QuestionReportDrawer
        v-if="selectedQuestion"
        :exam-id="Number(examId)"
        :has-next="!nextButtonDisabled"
        :has-previous="!previousButtonDisabled"
        :question="selectedQuestion"
        :question-index="selectedQuestionIndex + 1"
        @change-selected-question="changeSelectedQuestion($event)"
        @on-close="closeQuestionReportDrawer()"
      />
    </wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SHeader from '@/components/SHeader'
import HitsRate from '../components/HitsRate'
import PlacementHistogram from '../components/PlacementHistogram'
import StudentQuestionsReport from '../components/StudentQuestionsReport'
import QuestionReportDrawer from '../components/QuestionReportDrawer/QuestionReportDrawer'

import services from '../services'

export default {
  name: 'AssessmentReport',
  components: {
    SHeader,
    PlacementHistogram,
    HitsRate,
    StudentQuestionsReport,
    QuestionReportDrawer,
  },
  data() {
    return {
      loading: false,
      questions: [],
      selectedQuestion: null,
    }
  },
  computed: {
    ...mapGetters([
      'assessment',
      'applicationResponse',
    ]),
    assessmentId() {
      return this.$route.params.assessmentId
    },
    applicationId() {
      return this.$route.params.applicationId
    },
    examId() {
      return this.$route.params.examId
    },
    selectedQuestionIndex() {
      return this.questions.findIndex(
        (item) => item.id === this.selectedQuestion.id,
      )
    },
    previousButtonDisabled() {
      return this.selectedQuestionIndex === 0
    },
    nextButtonDisabled() {
      return this.selectedQuestionIndex === this.questions.length - 1
    },
    hitRate() {
      const percentageMultiplier = 100

      return (this.correctAnswersCount / this.questions.length)
        * percentageMultiplier
    },
    correctAnswersCount() {
      return this.questions.filter((item) => item.markedOption >= 0
        && item.markedOption === Number(item.correctOption)).length
    },
  },
  created() {
    this.fetchAnswerSheet()
  },
  methods: {
    ...mapActions([
      'getAssessment',
      'getApplicationResponse',
    ]),
    goBack() {
      this.$router.push({ name: 'personal-assessments' })
    },
    selectQuestion(id) {
      this.selectedQuestion = this.questions.find(
        (item) => item.id === id,
      )
    },
    changeSelectedQuestion(eventKey) {
      this.selectedQuestion = eventKey === 'previous'
        ? this.questions[this.selectedQuestionIndex - 1]
        : this.questions[this.selectedQuestionIndex + 1]
    },
    closeQuestionReportDrawer() {
      this.selectedQuestion = null
    },
    async fetchAnswerSheet() {
      try {
        this.loading = true
        await this.getAssessment(this.assessmentId)
        await this.getApplicationResponse({
          applicationId: this.applicationId,
          examId: this.examId,
        })
        const { data } = await services.fetchAnswerSheet(
          this.applicationResponse.id, this.examId,
        )
        this.questions = data
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('personalAssessment.feedback.fetchAnswerSheetError'),
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.assessment-report

  &__charts-section
    display: flex
    flex-flow: column
    padding: $size-m 0

    +mq-m--mf
      flex-flow: row
      justify-content: space-between

    &__hits-rate,
    &__placement-histogram
      width: 100%

      +mq-m--mf
        width: 48.5%
</style>
